import { makePrivateRequestAsUser } from "./managers/requestBuilder";
import { getUid } from "./managers/store";

import { getBrowserName, getDeviceModel, getDeviceType, getFingerprintId, getOSName } from "../utils/browser";
import { isMobileApp } from "../utils/getHost";
import { WEB_APP_VERSION } from "../utils/version";

// Instance id
// Random number assigned to this instance to identify it in logs when user has multiple tabs open
const INSTANCE_ID = Math.floor(Math.random() * 10000);
window.INSTANCE_ID = INSTANCE_ID; // Can be used to identify id through console

// Time web app first ran
const INSTANCE_START = new Date().getTime();

/**
 * All Athena logging goes through this function.
 * @param {string} type
 * @param {object} val
 */
const athenaAuthRequest = makePrivateRequestAsUser(async ({ makePrivateRequest }, type, val) => {
    if (
        !window.location.hostname?.match(/localhost/) &&

        // Must be logged in
        getUid()
    ) {
        makePrivateRequest('auth', 'POST', `/tracking/:uid/0/alog`, {
            data: {
                payload: {
                    type,
                    val: {
                        host: window.location.host,
                        fingerprint: await getFingerprintId(),
                        instance: INSTANCE_ID,
                        instanceStart: INSTANCE_START,
                        webAppVersion: WEB_APP_VERSION,
                        url: window.location.href,
                        app: isMobileApp,
                        ...val
                    }
                }
            }
        })
        .catch(console.error);
    }
});

// Temporary log to try to work out how many users can play H265
const logH265Support = async () => {

    const codecs = ['hvc1.1.6.L93.B0', 'hvc1.1.6.L120.90', 'hvc1.2.4.L120.90'].reduce((acc, codec) => ({
        ...acc,
        [codec]: document.createElement('video').canPlayType(`video/mp4; codecs="${codec}"`)
    }), {});

    return athenaAuthRequest(
        'h265_support',
        {
            canPlay: codecs['hvc1.1.6.L93.B0'], // For backwards compatibility
            ...codecs,
            browser: getBrowserName(),
            os: getOSName(),
            deviceModel: getDeviceModel(),
            deviceType: getDeviceType()
        }
    );
}

/**
 * Log user login or token refresh to Athena.
 * @param {boolean} newSession Is this a new session (i.e. has the web app just started up or been logged in to) or are we just refreshing a token?
 * @param {('password', 'sso', 'loginToken', 'demo')} type Type of login or token refresh. If `newSession` is false, this will always be "loginToken".
 * @param {string} seriesId Series id of login token returned.
 * @returns 
 */
export const logLogin = async (newSession, type, seriesId) => {

    // Temporary
    if (newSession) {
        logH265Support();
    }

    return athenaAuthRequest(
        'web_login',
        {
            newSession,
            type: type,
            seriesId,
            background: document.hidden,
            userAgent: navigator.userAgent,
            language: navigator.language,
            browser: getBrowserName(),
            os: getOSName(),
            deviceModel: getDeviceModel(),
            deviceType: getDeviceType(),
            localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    );
}

/**
 * Log that a refresh token failed and we are kicking the user out. Of course this will only work if authToken is still valid.
 * @param {string} seriesId Login token series id.
 * @param {string} errorMessage Message from error that was thrown when request failed.
 * @returns 
 */
export const logForcedLogout = async (seriesId, errorMessage) => {
    return athenaAuthRequest(
        'web_forced_logout',
        {
            seriesId,
            error: errorMessage
        }
    );
}

/**
 * Log error to Athena.
 * @param {string} message Error message.
 * @param {import('error-stack-parser').StackFrame[]} trace Stack trace.
 * @param {object} [info] Additional info that will be merged into data logged to Athena.
 * @param {string} [info.identifier] Identifier for where error came from. Can make searching for specific errors in Athena easier.
 * @returns {Promise}
 */
// Including "toAthena" in the name so it doesn't clash with `logError` in /utils/errors
export const logErrorToAthena = async (message, trace, info = {}) => {
    return athenaAuthRequest(
        'web_error',
        {
            message,
            trace: typeof trace === 'string' ? trace : JSON.stringify(trace),
            href: window.location.href,
            ...info
        }
    )
}

/**
 * Log timings for starting up a camera's live stream.
 * @param {string} deviceUidd Camera Uidd.
 * @param {boolean} localLive Was this through local live rather than Wowza?
 * @param {object} infoToLog
 * @param {number} infoToLog.startTime Timestamp of when process started.
 * @param {number} infoToLog.endTime Timestamp of when log period ended (e.g. video started playing).
 * @param {number} infoToLog.timeTaken `endTime` subtract `startTime`.
 * @param {string} [infoToLog.feature] Which feature is using the live stream? E.g. "singlecam"
 * @param {object} infoToLog.initialValues Initial values of key variables when process started.
 * @param {object[]} infoToLog.events Events that occured during process, e.g. camera starts live streaming.
 *                                    Each event object should contain `event` name and `time` timestamp,
 *                                    alongside any other relevant info.
 * @param {boolean} infoToLog.localLiveAvailable Does this camera offer local live?
 * @param {boolean} infoToLog.success Did we play video successfully?
 * @param {object[]} infoToLog.data Array of timings in old format that matches mobile app so our logs are
 *                                  still backwards compatible with Rob D's queries.
 * @param {object[]} events Timings of each step.
 * @returns Axios promise.
 */
export const logLiveStreamStartup = (deviceUidd, localLive, infoToLog) => {
    return athenaAuthRequest(
        'timing',
        {
            device: deviceUidd,
            action: localLive ? 'view_local_live' : 'view_live',
            ...infoToLog
        }
    );
}

/**
 * Log periodic info on a live stream.
 * @param {string} deviceUidd Camera uidd.
 * @param {object} infoToLog Object containing info about stream duration and buffering.
 * @returns Axios promise.
 */
export const logLiveStreamSummary = (deviceUidd, infoToLog) => {
    return athenaAuthRequest(
        'timing',
        {
            device: deviceUidd,
            action: 'live_stream_summary',
            ...infoToLog
        }
    );
}

/**
 * Log instances of live feed buffering to Athena.
 * @param {string} deviceUidd Camera Uidd.
 * @param {string} [feature] Which feature is using the live stream? E.g. "multicam"
 * @param {number} startTime Time when we started tracking buffering.
 * @param {object[]} bufferingLogs Instances of buffering. Each element object will include start time, duration, and source (local or Wowza).
 * @param {number} endTime Time when we stopped tracking buffering.
 * @returns 
 */
export const logLiveStreamBuffering = (deviceUidd, feature, startTime, bufferingLogs, endTime) =>
    athenaAuthRequest(
        'timing',
        {
            device: deviceUidd,
            action: 'live_buffering',
            startTime,
            buffering: bufferingLogs,
            endTime,
            feature
        }
    );

/**
 * Log where live stream had to fallback from local live to Wowza.
 * @param {string} deviceUidd Camera Uidd.
 * @param {string} localLiveAddress The address at which the local live server was found.
 * @param {obejct[]} errors Errors that occurred whilst trying to start the stream.
 * @returns Axios promise.
 */
export const logLocalLiveFallbackToWowza = (deviceUidd, localLiveAddress, errors) =>
    athenaAuthRequest(
        'local_live_fallback',
        {
            device: deviceUidd,
            localLiveAddress,
            errors
        }
    );

/**
 * Log result of checking whether local live server is reachable.
 * @param {boolean} success Is the local live server we were looking for available?
 * @param {string} message Extra info.
 * @param {object[]} errors Any errors we want recorded which occurred in testing local live addresses.
 * @returns 
 */
export const logLocalLiveAvailable = (success, message, errors) =>
    athenaAuthRequest(
        'local_live_test',
        {
            success,
            message,
            errors
        }
    );

/**
 * Log that a camera task was sent. Not currently used for all camera tasks but could be.
 * @param {string} cameraUidd Camera uidd.
 * @param {string} command Camera task.
 * @returns 
 */
export const logCameraTaskSent = (cameraUidd, command) => {
    return athenaAuthRequest(
        'web_camera_task',
        {
            device: cameraUidd,
            command
        }
    );
}
    
/**
 * Log timings for viewing an event.
 * @param {string} deviceUidd Camera Uidd.
 * @param {number} eventId Event id.
 * @param {object[]} timings Timings of each step.
 * @returns 
 */
export const logEventView = (deviceUidd, eventId, timings) =>
    athenaAuthRequest(
        'timing',
        {
            device: deviceUidd,
            action: 'view_event',
            event: eventId,
            data: timings,
        }
    );
    
/**
 * Log that an event was downloaded and how long it took.
 * @param {string} deviceUidd Camera Uidd.
 * @param {number} eventId Event id.
 * @param {object[]} timings Timings of each step.
 * @returns 
 */
export const logEventDownload = (deviceUidd, eventId, timings) =>
    athenaAuthRequest(
        'timing',
        {
            device: deviceUidd,
            action: 'download_event',
            event: eventId,
            data: timings,
        }
    );
    
/**
 * Log to Athena that a chunk was requested.
 * @param {number} startTime Timestamp when loading started.
 * @param {number} cutoffTime Timestamp when threshold was reached to consider it "stuck loading" and this log was triggered.
 * @param {object} startState Copy of any useful state data when loading started.
 * @param {object} endState Copy of any useful state data at `cutoffTime`.
 * @returns 
 */
export const logPlaybackStuckLoading = (startTime, cutoffTime, startState, endState) => {
    return athenaAuthRequest(
        'playback_loading',
        {
            startTime,
            cutoffTime,
            startState,
            endState
        }
    );
}

/**
 * Log to Athena that a chunk was requested.
 * @param {string} deviceUidd Device uidd.
 * @param {number} chunkStartTime Start time of chunk in ms.
 * @param {number} chunkEndTime End time of chunk in ms.
 * @param {number} timeTaken Time between request being sent and it returning in ms.
 * @param {number} httpStatus Request HTTP response status.
 * @param {object} chunkInfo Extra info on chunk.
 * @param {number} [chunkInfo.start] Number of seconds after requested start time that returned video starts.
 * @param {number} [chunkInfo.offset] Number of seconds before requested start time that returned video starts.
 * @param {number[][]} [chunkInfo.padding] Sections of video that are padded.
 * @returns Axios promise.
 */
export const logPlaybackChunkFetched = (deviceUidd, chunkStartTime, chunkEndTime, timeTaken, httpStatus, chunkInfo = {}) => {
    return athenaAuthRequest(
        'timing',
        {
            device: deviceUidd,
            action: 'build_playback_chunk',
            chunkStartTime,
            chunkEndTime,
            timeTaken,
            httpStatus,
            chunkStart: chunkInfo?.start,
            chunkSeek: chunkInfo?.offset,
            chunkPadding: chunkInfo?.padding
        }
    );
}

/**
 * Log that the browser reported a media error when trying to play playback chunk.
 * @param {string} deviceUidd Device uidd.
 * @param {number} chunkStartTime Start time of chunk in ms.
 * @param {number} chunkEndTime End time of chunk in ms.
 * @param {any} info Any info reported by mpegts.js.
 * @returns Axios promise.
 */
export const logPlaybackChunkMediaError = (deviceUidd, chunkStartTime, chunkEndTime, info) => {
    return athenaAuthRequest(
        'playback_chunk_media_error',
        {
            device: deviceUidd,
            chunkStartTime,
            chunkEndTime,
            info
        }
    );
}

/**
 * Log that the user changed a filter.
 * @param {string} feature Which page/feature is the filter from?
 * @param {object} filterValues Values of all relevant filters.
 * @param {object} [additionalInfo] Any additional info to include.
 * @param {string[]} [additionalInfo.filtersChanged] Names of filters which changed.
 * @returns Axios promise.
 */
export const logFilterChanged = (feature, filterValues, additionalInfo = {}) => {
    return athenaAuthRequest(
        'web_filter_changed',
        {
            feature,
            filterValues,
            ...additionalInfo
        }
    );
}

    
/**
 * Log changes to viewer timezone selection
 * @param {string} timezone Timezone selected
 * @param {Array} available List of available timezones to choose from
 * @param {string} page Page on which it was used
 * @returns 
 */
 export const logTzChange = (timezone, available, page) =>
 athenaAuthRequest(
     'timezone',
     {
        action: 'change_timezone',
        to: timezone,
        available,
        page
    }
 );

 /**
  * Log invalid timezone format error
  * @param {string} timezone Timezone selected
  * @returns 
  */
  export const logTzFormatError = (timezone) =>
  athenaAuthRequest(
      'timezone',
      {
         action: 'invalid_timezone',
         tz: timezone,
     }
  );

export const logSessionFetch = (uidds, start, end) => {
    athenaAuthRequest(
        'get_sessions',
        {
            uidds,
            start,
            end
        }
    );
};

// install

const adapterResponseStates = {
    200: 'adapter_found',
    500: 'cloud_id_servers_screwed',
    404: 'cloud_id_not_found',
    403: 'cloud_id_already_linked',
    400: 'cloud_id_general_error',
    401: 'cloud_id_unauthorised_error'
};

const athenaInstallLoggerRequest = (val) => athenaAuthRequest('ca_setup', val);

export const logIoTConnectionTimeout = () => {
    athenaInstallLoggerRequest({ action: 'iot_connection_timeout' });
};

export const logAdapterSearch = (responseState, cloudId) => {
    athenaInstallLoggerRequest({
        action: adapterResponseStates[responseState],
        other_info: {
            cloudid: cloudId
        }
    });
};

export const logHelloAction = (helloState) =>  {
    athenaInstallLoggerRequest({
        action: helloState ? 'hello_action_success' : 'hello_action_failure'
    });
};

export const logUnlinkAction = (unlinkState) =>  {
    athenaInstallLoggerRequest({
        action: unlinkState ? 'uninstall_action_success' : 'uninstall_action_failure'
    });
};

export const logDeviceAuth = (deviceHostname, deviceIpv4, deviceIpv6, serial, model, type, activation = false) => {
    let loggingPayload = {
        action: 'lookup_model',
        camera_or_xvr: {
            hostname: deviceHostname,
            ipv4: deviceIpv4 || '',
            ipv6: deviceIpv6 || '',
            serial: serial,
            model: model,
            type: type
        }    
    };
    if(activation) {
        loggingPayload= {
            ...loggingPayload,
            other_info: {
                activation: true
            }
        };
    }
    athenaInstallLoggerRequest(loggingPayload);
};

export const logSetupAction = (action, other_info) => {
    let log = {
        action: action,
    };
    if(other_info) {
        log = {
            ...log,
            other_info: other_info
        }
    }
    athenaInstallLoggerRequest(log);
};
   
export const logSelectedCameras = (selectedCams) => {
    let cams = [];
    for(let cam of selectedCams) {
        if(cam.device_kind === 'XVR') {
            cams.push(cam.serial + '@' + cam.channel);
        } else {
            cams.push(cam.serial);
        }
    }
    athenaInstallLoggerRequest({
        action: 'selected_cameras',
        other_info: {
            cameras: cams
        }
    });
};

export const logInstallAction = (devices) => {
    athenaInstallLoggerRequest({
        action: 'install_action_sent',
        other_info: {
            devices: devices.map(dev => ({long_serial: dev.serial, channel: dev.channel, key: dev.shadowKey}))
        }
    });
};

export const logConfigureParams = (config) => {
    athenaInstallLoggerRequest({
        action: 'configure_parameters',
        other_info: config
    });
};

export const logInstallError = (payload, response) =>{
    athenaInstallLoggerRequest({
        action: 'install_failed',
        other_info: {
            api_payload: payload,
            api_response: response
        }
    });
};

export const logGeneralShadowUpdateError = (cloudId, errorInfo, step) => {
    athenaInstallLoggerRequest({
        action: 'iot_update_fail',
        other_info: {
            cloudid: cloudId,
            step: step,
            error: errorInfo
        }
    });
};

export const logUserInfo = (browser, locale, localTime) => {
    athenaInstallLoggerRequest({
        action: 'browser_info',
        other_info: {
            browser: browser,
            locale: locale,
            localtime: localTime,
            localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    });
};

export const getCameraOrXvrLog = (addIpDevice) => {
    return {
        hostname: addIpDevice.shadowKey, 
        ipv4: addIpDevice.shadowKey, 
        serial: addIpDevice.info?.serial, 
        model: addIpDevice.info?.model,
        macAddress: addIpDevice.info?.macAddress
    };
};

export const logNetworkConnectionLost = () => {
    athenaInstallLoggerRequest({
        action: 'network_connection_error'
    });
};

// unlinking

const athenaUnlinkLogerRequest = (val) => athenaAuthRequest('unlink_adapter', val);

export const logUnlinkApiCall = (status, cloudId) => {
    if(status === 200) {
        athenaUnlinkLogerRequest({
            action: 'unlink_success_200',
            other_info: {
                cloudid: cloudId
            }
        });
    } else {
        athenaUnlinkLogerRequest({
            action: 'unlink_failure_' + status,
            other_info: {
                cloudid: cloudId
            }
        });
    }
};

export const logUninstallActionSent = (res, cloudId) => {
    athenaUnlinkLogerRequest({
        action: res ? 'uninstall_action_sent_success' : 'uninstall_action_sent_failure',
        other_info: {
            cloudid: cloudId
        }
    });
};

export const logUninstallActionRemoved = (res, cloudId) => {
    athenaUnlinkLogerRequest({
        action: res ? 'uninstall_action_remove_success' : 'uninstall_action_remove_failure',
        other_info: {
            cloudid: cloudId
        }
    });
};

/**
 * 
 * @param {string} action - Specifies the action about to be performed. Must be either 'uninstall', 'uninstall_complete' or 'delete'.
 * @param {string[]} devices - An array of UIDDs representing the devices to be processed. 
 * @returns {void} 
 */
export const logDashboardCameraAction = (action, devices, other_info) => {
    athenaAuthRequest(
        'cameras_action',
        {
            action,
            devices,
            other_info
        }
    );
};

export const logUninstallTask = (devices, info) => {
    logDashboardCameraAction('uninstall_task', devices, info);
};


/* settings */
export const logSettingUpdate = (devices, setting) => {
    athenaAuthRequest(
        'setting_update',
        {
            devices,
            setting
        }
    );
};

export const logSettingsPopup = (type, id, devices) => {
    athenaAuthRequest(
        'setting_popup',
        {
            type,
            id,
            devices
        }
    );
};

export const logSettingsFetched = (devices) => {
    athenaAuthRequest(
        'settings_fetched',
        {
            devices
        }
    );
};