import _ from 'lodash';
import axios from 'axios';

import { getAuthToken } from './managers/store';
import { makePrivateRequestAsUser, privateAuthenticatorRequest, privateLoggerRequest } from './managers/requestBuilder';

import { logEventDownload } from './athena';
import { getRecordedVideoData } from './playback';

import { roundTo2dp } from '../utils/maths';
import { downloadFile } from '../utils/download';
import { getEventThumbSrc } from '../utils/events';
import { getAcronym } from '../utils/string';
import { formatDuration, getFriendlyTimezoneName } from '../utils/datetime';

/* --------------------------------- Helpers -------------------------------- */

/**
 * @typedef Event
 * @type {object}
 * @property {number} id Id.
 * @property {Date} startTime Start date and time.
 * @property {Date} endTime End date and time.
 * @property {number} durationSeconds Duration of event in seconds.
 * @property {boolean} rom
 * @property {(0,1,2,3,4,5,6,7,8,9,10)} motionScore Motion score.
 * @property {string} logger
 * 
 * @property {string} cameraUidd Uidd of recording camera.
 * @property {string} deviceId Device id of recording camera.
 * @property {boolean} hasHighResStill Whether there is a high res still available for this event.
 */

/**
 * 
 * @param {*} param0 
 * @returns 
 */

export const getEventObject = ({
    alert,
    id,
    startt,
    endt,
    rom,
    smx,
    uidd,
    uid,
    logger,
    deviceid,
    has_hdstill
}) => ({
    id: alert ?? id,
    startTime: new Date(startt),
    endTime: new Date(endt),
    // Manything durations may not be whole seconds
    durationSeconds: Math.round((endt - startt) / 1000),
    rom: !!rom,

    // Realtime API sends motion score in hex
    // Events API sends motion score in decimal
    motionScore: smx === '0a' ? 10 : parseInt(smx),

    logger,
    cameraUidd: uidd ?? `${uid}.${deviceid}`,
    uid: uid ?? uidd?.split('.')?.[0],

    // GET /events returns deviceid but realtime API does not so use uidd in this case
    deviceId: deviceid ?? uidd?.split('.')?.[1],

    hasHighResStill: !!has_hdstill
});

const getEventRequestParams = (
    uidds,
    endTime,
    startTime,
    minDurationSeconds,
    maxDurationSeconds
) => ({
    uidd: uidds,

    // These times must be in milliseconds
    startt: startTime?.getTime ? startTime.getTime() : startTime,
    endt: endTime?.getTime ? endTime.getTime() : endTime,

    minDurationSeconds,
    maxDurationSeconds,
});

// Constant
export const MAX_EVENT_REQUEST_TIME_SPAN_IN_DAYS = 30;

/**
 * Return events meeting the specified filters. Backend currently returns at most 50 events per request.
 * Difference between start and end time must not exceed `MAX_EVENT_REQUEST_TIME_SPAN_IN_DAYS`.
 * @param {string} logger Logger of any camera.
 * @param {Array<string>} uidds Array of camera uidds.
 * @param {Date|number} [endTime] Max time of the START of the event.
 * @param {Date|number} [startTime] Min time of the START of the event.
 * @param {number} [minDurationSeconds] Min duration in seconds. This slows down db query so we don't use it anymore.
 * @param {number} [maxDurationSeconds] Max duration in seconds. This slows down db query so we don't use it anymore.
 * @param {Array<string>} [analyticsLabels] Rekog labels.
 * @returns {[Array<Event>, boolean]} Array of event objects,
 *                                     boolean indicating whether we are sure that all events meeting these filters were returned (i.e. number returned was less than 50 limit)
 */
export const getEvents = async (
    logger,
    uidds,
    endTime,
    startTime = 0,
    minDurationSeconds,
    maxDurationSeconds,
    analyticsLabels
) => {

    // Backend returns 50 events
    // If we receive less, that means we've got them all and there are no more to fetch
    const LIMIT = 50;

    const { data } = await privateLoggerRequest(logger, 'GET', '/events', {
        params: {
            ...getEventRequestParams(
                uidds,
                endTime,
                startTime,
                minDurationSeconds,
                maxDurationSeconds
            ),

            rekogLabel: analyticsLabels ?? undefined,
        },
    });

    return [
        data.map((event) => getEventObject({ ...event })),
        data.length < LIMIT,
    ];
};

/**
 * Return all events meeting the specified filters, without a limit.
 * Difference between start and end time must not exceed `MAX_EVENT_REQUEST_TIME_SPAN_IN_DAYS`.
 * @param {string} logger Logger of any camera.
 * @param {Array<string>} uidds Array of camera uidds.
 * @param {Date|number} [endTime] Max time of the START of the event.
 * @param {Date|number} [startTime] Min time of the START of the event.
 * @param {number} [minDurationSeconds] Min duration in seconds. This slows down db query so we don't use it anymore.
 * @param {number} [maxDurationSeconds] Max duration in seconds. This slows down db query so we don't use it anymore.
 * @param {Array<string>} [analyticsLabels] Rekog labels.
 * @returns {[Array<Event>, boolean]} Array of event objects,
 *                                     boolean indicating whether we are sure that all events meeting these filters were returned (i.e. number returned was less than 50 limit)
 */
export const getAllEvents = async (
    logger,
    uidds,
    endTime,
    startTime = 0,
    minDurationSeconds,
    maxDurationSeconds,
    analyticsLabels
) => {

    const { data } = await privateLoggerRequest(logger, 'GET', '/events', {
        params: {
            ...getEventRequestParams(
                uidds,
                endTime,
                startTime,
                minDurationSeconds,
                maxDurationSeconds
            ),
            rekogLabel: analyticsLabels ?? undefined,
            removeLimit: true
        },
    });

    return data.map((event) => getEventObject({ ...event }));
};
export const getAllSessions = async (
    uidds,
    minstart,
    maxstart,
) => {

    const { data } = await privateAuthenticatorRequest('GET', '/sessions', {
        params: {
            uidd: uidds,
            startBefore: maxstart?.getTime ? maxstart.getTime() : maxstart,
            startAfter: minstart?.getTime ? minstart.getTime() : minstart,
        },
    });
    return data;
};

export const getEvent = async (logger, uidd, startTime, endTime) => {
    const [events] = await getEvents(logger, uidd, endTime, startTime);

    if (events.length === 0) {
        throw new Error('Not found');
    }

    return events[0];
};

export const getRekogLabels = async (
    logger,
    uidds,
    endTime,
    startTime = 0,
    minDurationSeconds,
    maxDurationSeconds
) => {
    const {
        data: { result },
    } = await privateLoggerRequest(logger, 'GET', `/rekog/labels/count`, {
        params: {
            ...getEventRequestParams(
                uidds,
                endTime,
                startTime,
                minDurationSeconds,
                maxDurationSeconds
            ),
        },
    });

    return result.reduce(
        (acc, labelCount) => ({
            ...acc,
            [labelCount.label]: labelCount['count(*)'],
        }),
        {}
    );
};

export const deleteEventsForCamera = async (
    logger,
    cameraUidd,
    eventIds
) => {
    const [ownerUid, deviceId] = cameraUidd.split('.');

    // Each event id uses 19 characters (`eventIds=1234567890` = 19 characters)
    // Cap the query string at 1805 characters to leave plenty of room for rest of URI and guarantee we won't exceed limit
    const MAX_EVENTS_PER_REQUEST = 95;

    const batches = eventIds.reduce((acc, eventId) => {
        const currentBatch = acc[acc.length - 1];
        if (currentBatch.length >= MAX_EVENTS_PER_REQUEST) {
            return acc.concat([[eventId]]);
        } else {
            currentBatch.push(eventId);
        }
        return acc;
    }, [[]]);

    return await Promise.all(batches.map(batch => (
        privateLoggerRequest(
            logger,
            'DELETE',
            `/events/${ownerUid}/${deviceId}`,
            {
                params: {
                    eventIds: batch,
                },
            }
        )
    )));
};

// Delete events across multiple cameras and loggers
// All cameras must belong to same owner
// events is array where each element is object with properties `logger`, `deviceId` and event `id`
export const deleteEvents = async (events) => {
    const loggersToCamerasToEvents = {};

    events.forEach(({ logger, cameraUidd, id }) => {
        if (!loggersToCamerasToEvents[logger]) {
            loggersToCamerasToEvents[logger] = {};
        }

        if (!loggersToCamerasToEvents[logger][cameraUidd]) {
            loggersToCamerasToEvents[logger][cameraUidd] = [];
        }

        loggersToCamerasToEvents[logger][cameraUidd].push(id);
    });

    const loggersAndCameras = _.reduce(
        loggersToCamerasToEvents,
        (acc, camerasToEvents, logger) => {
            return acc.concat(
                _.map(camerasToEvents, (eventIds, cameraUidd) => {
                    return { logger, cameraUidd, eventIds };
                })
            );
        },
        []
    );

    return Promise.all(
        loggersAndCameras.map(({ logger, cameraUidd, eventIds }) =>
            deleteEventsForCamera(logger, cameraUidd, eventIds)
        )
    );
};

/**
 * Download event to user's computer.
 * @param {string} logger Logger used to report timings to Athena.
 * @param {string} cameraUidd Camera uidd.
 * @param {number} eventId Event id.
 * @param {Date} startTime Start time of event. An extra ~5s lead in before this time will be included in download.
 * @param {Date} endTime End time of event.
 * @param {string} cameraName Camera name.
 * @param {string} cameraStreamName Camera stream name.
 */
export const downloadEvent = async (
    logger,
    cameraUidd,
    eventId,
    startTime,
    endTime,
    cameraName,
    cameraStreamName,
    cameraTimezone,
    viewerTimezone
) => {
    // Record start time to log to Athena
    const downloadStartTime = new Date().getTime() / 1000;

    const mp4 = await getRecordedVideoData(
        cameraUidd,
        // Include lead in
        startTime.getTime() - 1000,
        endTime.getTime(),
        cameraStreamName
    );

    // Log to Athena that download has completed
    // The code after this takes no time at all so doesn't need to be included
    const downloadEndTime = new Date().getTime() / 1000;
    logEventDownload(
        cameraUidd,
        eventId,
        // Try to mimic mobile app with timings array (though this is more complex than needed for web)
        // Mobile app example: [{"start":"1661510712.71"},{"downloaded":"0.32"},{"processed":"0.00"},{"completed":"0.00"},{"stop":"1661510713.03"},{"total":"0.32"}]
        [
            { start: roundTo2dp(downloadStartTime) },
            { downloaded: roundTo2dp(downloadEndTime - downloadStartTime) },
            { stop: roundTo2dp(downloadEndTime) },
            { total: roundTo2dp(downloadEndTime - downloadStartTime) }
        ]
    );

    const formatTzAndDateTimeForFilename = (time, timezone = Intl.DateTimeFormat().resolvedOptions().timeZone) => (`${getAcronym(getFriendlyTimezoneName(timezone))}_${time.toLocaleDateString(undefined, { timeZone: timezone }).replaceAll('/', '-')}-${time.toLocaleTimeString(undefined, { timeZone: timezone }).replaceAll(':', '-')}`);

    let camTz = '';
    const viewerTz = formatTzAndDateTimeForFilename(startTime, viewerTimezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone);
    if (cameraTimezone) {
        camTz = formatTzAndDateTimeForFilename(startTime, cameraTimezone);
    }
    const fileName = `${cameraName}_${viewerTimezone && viewerTimezone !== cameraTimezone ? viewerTz + '_' +  camTz : camTz}_${formatDuration(endTime-startTime)}`;

    downloadFile(
        mp4,
        `${fileName}.mp4`
    );
};

export const getEventAnalytics = async (logger, cameraUidd, eventId) => {
    const { data } = await privateLoggerRequest(logger, 'GET', '/alert/rekog', {
        params: {
            uid: cameraUidd,
            alertid: eventId,
            token: getAuthToken(),
        },
    });

    return data.result.sort(({ confidence: a }, { confidence: b }) => b - a);
};

/**
 * @typedef VehicleData
 * @type {object}
 * @property {number} uid Camera owner uid.
 * @property {number} deviceId Camera device id.
 * @property {number} eventId Event id.
 * @property {string} licencePlate Licence plate.
 * @property {string} licencePlateRegion Region licence plate is from.
 * @property {number} confidence Confidence between 0 and 100.
 * @property {number} stillTimeMs Timestamp when vehicle was detected.
 * @property {string} make Vehicle manufacturer.
 * @property {string} model Vehicle model.
 * @property {string} colour Colour of vehicle.
 */

/**
 * Get list of vehicles in an event (for supported cameras).
 * @param {string} logger
 * @param {string} cameraUidd
 * @param {number} eventId
 * @returns {VehicleData[]} Array of vehicles in event.
 */
export const getEventVehicleAnalytics = makePrivateRequestAsUser(async ({ makePrivateRequest }, logger, cameraUidd, eventId) => {
    const [uid, deviceId] = cameraUidd.split('.');
    
    const { data } = await makePrivateRequest(
        logger,
        'GET',
        // Cache bust this in case there are still more vehicles to process for the event
        `/events/${uid}/${deviceId}/${eventId}/analytics/vehicles?t=${new Date().getTime()}`
    );

    return data.map(({ uid, deviceid, alertid, licence_plate, licence_plate_region, confidence, still_time_ms, make, model, colour }) => ({
        uid,
        deviceId: deviceid,
        eventId: alertid,
        licencePlate: licence_plate,
        licencePlateRegion: licence_plate_region,
        confidence,
        stillTimeMs: still_time_ms,
        make,
        model,
        colour
    }));
});

// timestamp as Date object or in ms
export const getHighResStill = async (
    logger,
    ownerUid,
    deviceId,
    timestamp
) => {
    const { data } = await privateLoggerRequest(
        logger,
        'GET',
        `/simple/${ownerUid}/${deviceId}/hdstill/${
            timestamp instanceof Date
                ? timestamp.getTime() / 1000
                : timestamp / 1000
        }.jpg`,
        {
            responseType: 'blob',
        }
    );

    return data;
};

export const getEventThumbBase64 = async (logger, cameraUidd, eventId) => {

    const { data } = await axios({
        url: getEventThumbSrc(logger, cameraUidd, eventId, getAuthToken()),
        responseType: 'arraybuffer'
    });

    return Buffer.from(data, 'binary').toString('base64');
}