import translationKeys from '../translations/keys';
import { isDevelopment } from './getHost';
import { getFieldValue } from './maths';

export const comingSoonPrices = {
    discounted: {
        'USD': 2.49
    },
    'AUD': 4.49,
    'CAD': 4.09,
    'EUR': 2.79,
    'GBP': 2.39,
    'NZD': 4.99,
    'USD': 2.99
};

const currencySymbols = {
    AED: 'د.إ',
    AFN: 'Af',
    ALL: 'L',
    AMD: 'Դ',
    AOA: 'Kz',
    ARS: '$',
    AUD: 'AU$',
    AWG: 'ƒ',
    AZN: 'ман',
    BAM: 'КМ',
    BBD: '$',
    BDT: '৳',
    BGN: 'лв',
    BHD: 'ب.د',
    BIF: '₣',
    BMD: '$',
    BND: '$',
    BOB: 'Bs.',
    BRL: 'R$',
    BSD: '$',
    BTN: '',
    BWP: 'P',
    BYR: 'Br',
    BZD: '$',
    CAD: 'C$',
    CDF: '₣',
    CHF: '₣',
    CLP: '$',
    CNY: '¥',
    COP: '$',
    CRC: '₡',
    CUP: '$',
    CVE: '$',
    CZK: 'Kč',
    DJF: '₣',
    DKK: 'kr',
    DOP: '$',
    DZD: 'د.ج',
    EGP: '£',
    ERN: 'Nfk',
    ETB: '',
    EUR: '€',
    FJD: '$',
    FKP: '£',
    GBP: '£',
    GEL: 'ლ',
    GHS: '₵',
    GIP: '£',
    GMD: 'D',
    GNF: '₣',
    GTQ: 'Q',
    GYD: '$',
    HKD: '$',
    HNL: 'L',
    HRK: 'Kn',
    HTG: 'G',
    HUF: 'Ft',
    IDR: 'Rp',
    ILS: '₪',
    INR: '₨',
    IQD: 'ع.د',
    IRR: '﷼',
    ISK: 'Kr',
    JMD: '$',
    JOD: 'د.ا',
    JPY: '¥',
    KES: 'Sh',
    KGS: '',
    KHR: '៛',
    KPW: '₩',
    KRW: '₩',
    KWD: 'د.ك',
    KYD: '$',
    KZT: '〒',
    LAK: '₭',
    LBP: 'ل.ل',
    LKR: 'Rs',
    LRD: '$',
    LSL: 'L',
    LYD: 'ل.د',
    MAD: 'د.م.',
    MDL: 'L',
    MGA: '',
    MKD: 'ден',
    MMK: 'K',
    MNT: '₮',
    MOP: 'P',
    MRO: 'UM',
    MUR: '₨',
    MVR: 'ރ.',
    MWK: 'MK',
    MXN: '$',
    MYR: 'RM',
    MZN: 'MTn',
    NAD: '$',
    NGN: '₦',
    NIO: 'C$',
    NOK: 'kr',
    NPR: '₨',
    NZD: '$',
    OMR: 'ر.ع.',
    PAB: 'B/.',
    PEN: 'S/.',
    PGK: 'K',
    PHP: '₱',
    PKR: '₨',
    PLN: 'zł',
    PYG: '₲',
    QAR: 'ر.ق',
    RON: 'L',
    RSD: 'din',
    RUB: 'р. ',
    RWF: '₣',
    SAR: 'ر.س',
    SBD: '$',
    SCR: '₨',
    SDG: '£',
    SEK: 'kr',
    SGD: '$',
    SHP: '£',
    SLL: 'Le',
    SOS: 'Sh',
    SRD: '$',
    STD: 'Db',
    SYP: 'ل.س',
    SZL: 'L',
    THB: '฿',
    TJS: 'ЅМ',
    TMT: 'm',
    TND: 'د.ت',
    TOP: 'T$',
    TRY: '₤',
    TTD: '$',
    TWD: '$',
    TZS: 'Sh',
    UAH: '₴',
    UGX: 'Sh',
    USD: '$',
    UYU: '$',
    UZS: '',
    VEF: 'Bs F',
    VND: '₫',
    VUV: 'Vt',
    WST: 'T',
    XAF: '₣',
    XCD: '$',
    XPF: '₣',
    YER: '﷼',
    ZAR: 'R',
    ZMW: 'ZK',
    ZWL: '$',
};

// If we haven't got a symbol, return the code
export const getCurrencySymbol = (currency) =>
    currencySymbols[currency] || currency;

// Show number prefixed by currency symbol
// Always shown to 2dp by default
// If alwaysIncludeDecimals is false and value is an integer, number shown with no decimals instead
export const showPrice = (currency, value, alwaysIncludeDecimals = true) => {
    const decimalPlaces = alwaysIncludeDecimals || value % 1 !== 0 ? 2 : 0;

    const price = Number(value).toLocaleString(
        undefined,
        {
            maximumFractionDigits: decimalPlaces,
            minimumFractionDigits: decimalPlaces,
            signDisplay: 'never'
        }
    );
    
    return (value < 0 ? '-' : '') + getCurrencySymbol(currency) + price;
};

export const calculatePricePerCloudPlanItem = (
    itemAmount,
    resolution,
    recordingMode,
    cloudStorageDays,
    paymentInterval,
    totalCameraCount,
    cloudPlanPricing,
    taxRate = 0
) => {
    const price = cloudPlanPricing[resolution][recordingMode][cloudStorageDays][paymentInterval];
    if (typeof price === 'number') {
        return calcPriceFromNet(itemAmount * price, taxRate, false);
    } else {
        // get all the limits in descending order
        const camLowerLimits = [...new Set(price.map(p => p.camLowerLimit))].sort((a, b) => b - a);
        // find the first limit to use for the total amount of cams
        const closestLimit = camLowerLimits.find(limit => limit <= totalCameraCount);
        const priceForLimit = price.find(p => p.camLowerLimit === closestLimit);
        return calcPriceFromNet(itemAmount * priceForLimit.fee, taxRate, false);
    }
};

export const calculateCloudPlanPrice = (
    cloudPlanPricing,
    formValues,
    taxRate = 0
) => {
    let totalCameraCount = getCloudCameraCount(formValues);
    let finalPrice = 0;
    iterateOverResolutionsAndModes((resolution, recordingMode) => {
        let cameraAmount = 0;
        cameraAmount += parseInt(getFieldValue(formValues[`cameras${resolution}_${recordingMode}`]));
        const prices = cloudPlanPricing[resolution][recordingMode][getFieldValue(formValues.cloudStorageDays) ?? '30'][getFieldValue(formValues.paymentInterval) ?? 'monthly'];
        // get all the limits in descending order
        const camLowerLimits = [...new Set(prices.map(p => p.camLowerLimit))].sort((a, b) => b - a);
        // find the first limit to use for the total amount of cams
        const closestLimit = camLowerLimits.find(limit => limit <= totalCameraCount);
        finalPrice += 
            cameraAmount *
            cloudPlanPricing
                [resolution]
                [recordingMode]
                [
                    getFieldValue(formValues.cloudStorageDays) ?? '30'
                ][
                    getFieldValue(formValues.paymentInterval) ?? 'monthly'
                ].reduce(
                (acc, { camLowerLimit, fee }) => 
                    typeof camLowerLimit === 'undefined' ? fee: 
                        closestLimit === camLowerLimit ? fee : acc,
                0
            )
        ;
    });
    return calcPriceFromNet(finalPrice, taxRate, false);
};

export const generateCloudStorageDurationOption = (days, t) => {
    let text = t(
        translationKeys.time.X_DAYS,
        {
            count: parseInt(days),
        }
    );
    if (
        days >= 365 &&
        days % 365 === 0
    ) {
        text = t(
            translationKeys.time
                .X_YEARS,
            { count: days / 365 }
        );
    } else if(days > 365 && (days - 1) % 365 === 0) {
        text = t(
            translationKeys.time.X_YEARS,
            { count: (days - 1) / 365}
        )
    } else {
        text = t(
            translationKeys.time
                .X_DAYS,
            { count: days }
        );
    }
    return {
        value: days,
        text,
        tag:
            days === 30
                ? t(
                    translationKeys
                        .pricing
                        .POPULAR
                )
            : undefined,
    };
}

const resolutions = ['2MP', '4MP', '8MP'];
const recordingModes = ['rom', 'continuous'];

export const iterateOverResolutionsAndModes = (f) => {
    for (const resolution of resolutions) {
        for (const recordingMode of recordingModes) {
            f(resolution, recordingMode);
        }
    }
};

// Function to add up number of cloud cameras chosen from form values
export const getCloudCameraCount = (values) => {
    let total = 0;
    iterateOverResolutionsAndModes((resolution, recordingMode) => {
        let cam = values[`cameras${resolution}_${recordingMode}`];
        total += getFieldValue(cam);
        
    });
    return total;
};

export const selectedCamerasListFromQuote = (quote, paymentInterval, siteIdx, t) => {
    let list = [];
    let site = quote.sites[siteIdx];
    iterateOverResolutionsAndModes((resolution, recordingMode) => {
        if (site[resolution] && site[resolution][recordingMode]) {
            let price = site[resolution][recordingMode].price[paymentInterval === 'monthly' ? 1 : 12];
            price = calcPriceFromNet(price, quote.taxRate, false);
            list.push(
                {
                    key: `cameras${resolution}_${recordingMode}`,
                    value: 
                        t(translationKeys.registration.plan.plan_summary[`X_${resolution}_SELECTED`], { count: site[resolution][recordingMode].amount}) + ' ' + 
                        t(translationKeys.technical.recordingModes[recordingMode.toUpperCase()]) + ' - ' +
                        showPrice(quote.currency, price)
                }
            );
        }
    });
    if (site['object_detection']) {
        let price = site['object_detection'].price[paymentInterval === 'monthly' ? 1 : 12];
        price = calcPriceFromNet(price, quote.taxRate, false);
        list.push({
            key: `analytics_cameras`,
            value: t(translationKeys.registration.plan.plan_summary.X_ANALYTICS_SELECTED, { count:  site['object_detection'].amount}) + ' - ' + showPrice(quote.currency, price)
        });
    }
    return <>{list.map(({key, value}) => (<span key={key}>{value}</span>))}</>;
};

// function to display list of cameras and prices
export const selectedCamerasList = (
    chosenPlan,
    cloudPlanPricing,
    totalCameraCount,
    t
) => {
    let list = [];
    iterateOverResolutionsAndModes((resolution, recordingMode) => {
        let cameraAmount = 0;
        cameraAmount += parseInt(getFieldValue(chosenPlan[`cameras${resolution}_${recordingMode}`]));
        if (
            cameraAmount > 0
        ) {
            let priceForItem = calculatePricePerCloudPlanItem(
                cameraAmount,
                resolution,
                recordingMode,
                getFieldValue(chosenPlan.cloudStorageDays) ?? '30',
                getFieldValue(chosenPlan.paymentInterval) ?? 'monthly',
                totalCameraCount,
                cloudPlanPricing.plans,
                cloudPlanPricing.taxRate
            );
            list.push(
                {
                    key: `cameras${resolution}_${recordingMode}`,
                    value: 
                        t(translationKeys.registration.plan.plan_summary[`X_${resolution}_SELECTED`], { count: cameraAmount}) + ' ' + 
                        t(translationKeys.technical.recordingModes[recordingMode.toUpperCase()]) + ' - ' +
                        (showPrice(
                            cloudPlanPricing.currency, 
                            priceForItem,
                            false
                        ))
                }
            );
        }
    });

    return list;
};

const countSitesWithCameraType = (formValues, extraSites, camType, t) => {
    let count = 0;
    if (
        extraSites && Object.keys(extraSites).length > 0
    ) {
        Object.values(extraSites).map(
            site =>
                site.fieldsProps[camType].value > 0 ?
                    count += 1 :
                    count += 0
        );
        let firstSiteCamAmount = getFieldValue(formValues[camType])
        if (firstSiteCamAmount > 0) {
            count += 1;
        }
        if (count > 0) {
            return t(translationKeys.registration.plan.plan_summary.X_SITES, { count: count});
        } else return '';
    } else return '';
};

export const getPriceListFromQuote = (quote, renewalFrequency, t) => {
    let allCams = {};
    quote.sites.forEach((site) => {
        iterateOverResolutionsAndModes((resolution, recordingMode) => {
            if (site[resolution] && site[resolution][recordingMode]) {
                let orderItemKey = `${resolution}_${recordingMode}`;
                if (allCams[orderItemKey]) {
                    allCams = {
                        ...allCams,
                        [orderItemKey]: {
                            ...allCams[orderItemKey],
                            amount: allCams[orderItemKey].amount + site[resolution][recordingMode].amount,
                            price: Number(Number(allCams[orderItemKey].price) + Number(site[resolution][recordingMode].price[renewalFrequency === 'monthly' ? 1 : 12])).toFixed(2)
                        }
                    }
                } else {
                    allCams = {
                        ...allCams,
                        [orderItemKey]: {
                            amount:site[resolution][recordingMode].amount,
                            price: Number(site[resolution][recordingMode].price[renewalFrequency === 'monthly' ? 1 : 12]).toFixed(2),
                            name: t(translationKeys.technical.resolutions[resolution]) + ' ' + t(translationKeys.technical.recordingModes[recordingMode.toUpperCase()])
                        }
                    }
                }
            }
        });
        if (site['object_detection']) {
            if (allCams.analyticsCameras) {
                allCams = {
                    ...allCams,
                    analyticsCameras: {
                        amount: allCams.analyticsCameras.amount + site['object_detection'].amount,
                        price: Number(Number(allCams.analyticsCameras.price) + Number(site['object_detection'].price[quote.renewalFrequency])).toFixed(2)
                    }
                }
            } else {
                allCams = {
                    ...allCams,
                    analyticsCameras: {
                        amount: site['object_detection'].amount,
                        price: Number(site['object_detection'].price[quote.renewalFrequency]).toFixed(2)
                    }
                }
            }
        }
    });

    if (allCams.analyticsCameras) {
        allCams.analyticsCameras.name = t(translationKeys.registration.plan.plan_summary.X_ANALYTICS_SELECTED, { count: allCams.analyticsCameras.amount });
    }
    const sortedCamsKeys = Object.keys(allCams).sort();
    let sortedAllCams = {};
    sortedCamsKeys.forEach(key => {
        sortedAllCams[key] = allCams[key];
    });
    let list = [];
    Object.values(sortedAllCams).map(({amount, price, name}) => {
        const itemPrice = calcPriceFromNet(price, quote.taxRate, false);
        list.push(
            <span 
                key={name}
                style={{
                    fontWeight:
                        '100',
                }}
            >
                {name.includes(amount + ' × ') ? '' : amount + ' × '} {name} - {showPrice(quote.currency, itemPrice)}
            </span>
        )
    });
    return <>{list}</>;
};

export const getPriceList = (
    formValues,
    extraSites, 
    cloudStorageDays, 
    paymentInterval, 
    cloudPlanPricing, 
    t, 
    includeSites = false,
    includeTax = false,
) => {
    let list = [];
    let totalCameraCount = 0;
    iterateOverResolutionsAndModes((resolution, recordingMode) => { 
        if (formValues[`cameras${resolution}_${recordingMode}`] > 0) {
            totalCameraCount += parseInt(formValues[`cameras${resolution}_${recordingMode}`]);
        }
        if (
            extraSites &&
            Object.keys(extraSites)?.length > 0
        ) {
            Object.values(extraSites).map(
                site => 
                    parseInt(site.fieldsProps[`cameras${resolution}_${recordingMode}`].value) > 0 ? 
                        totalCameraCount += parseInt(site.fieldsProps[`cameras${resolution}_${recordingMode}`].value) : 
                        totalCameraCount += 0
            );
        }
    });
    iterateOverResolutionsAndModes((resolution, recordingMode) => {
        let amountOfCameras = parseInt(formValues[`cameras${resolution}_${recordingMode}`]) ?? 0;
        if (
            extraSites &&
            Object.keys(extraSites)?.length > 0
        ) {
            Object.values(extraSites).map(
                site => 
                    parseInt(site.fieldsProps[`cameras${resolution}_${recordingMode}`].value) > 0  ? 
                        amountOfCameras += parseInt(site.fieldsProps[`cameras${resolution}_${recordingMode}`].value) : 
                        amountOfCameras += 0
            );
        }
        if (amountOfCameras > 0) {
            let priceForItem = calculatePricePerCloudPlanItem(
                amountOfCameras,
                resolution,
                recordingMode,
                cloudStorageDays ?? '30',
                paymentInterval ?? 'monthly',
                totalCameraCount,
                cloudPlanPricing.plans,
                cloudPlanPricing.taxRate
            );
            list.push(
                <span 
                    key={`${resolution}_${recordingMode}`}
                    style={{
                        fontWeight:
                            '100',
                    }}
                >
                    {
                        amountOfCameras
                    }{' '}
                    x {t(translationKeys.technical.resolutions[resolution])} {' '} {t(translationKeys.technical.recordingModes[recordingMode.toUpperCase()])} {includeSites && countSitesWithCameraType(formValues, extraSites, `cameras${resolution}_${recordingMode}`, t)} - {
                        showPrice(
                            cloudPlanPricing.currency,
                            priceForItem
                        )
                    }
                </span>
            );
        }
    });
    return (
        <>
            {list}
        </>
    )
};

export const calculateCloudPlanPriceWithExtraSites = (cloudPlanPricing, firstSite, extraSites, cloudStorageDays, paymentInterval) => {
    const totalCams = {...firstSite};
    if (extraSites && Object.keys(extraSites)?.length > 0) {
        Object.values(extraSites).forEach(site => {
                totalCams.analyticsCameras += parseInt(getFieldValue(site.fieldsProps.analyticsCameras));
                iterateOverResolutionsAndModes((resolution, recordingMode) => 
                    totalCams[`cameras${resolution}_${recordingMode}`] += parseInt(getFieldValue(site.fieldsProps[`cameras${resolution}_${recordingMode}`]))
                );
            }
        );
    
    }

    let totalPrice = calculateCloudPlanPrice(
        cloudPlanPricing.plans,
        { ...totalCams, cloudStorageDays: cloudStorageDays, paymentInterval: paymentInterval },
        cloudPlanPricing.taxRate
    );
    return totalPrice;
};

export const calcPriceFromNet = (netPrice, taxRate, includeTax) => {
    return Number(netPrice * (includeTax ? (1 + taxRate) : 1)).toFixed(2);
};


// All self reg installer ids
export const SELF_REG_INSTALLER_IDS = {
    discountOregon: 'fap7lgsg2s',
    discountProduction: 'qp6bwm2okq',
    defaultOregon: '0bgihnmo8m',
    defaultProduction: '2blxr6k9na'
};

// All self reg distributor ids
const SELF_REG_DISTRIBUTOR_IDS = {
    discountOregon: 'testregion',
    discountProduction: 'regional01',
    defaultOregon: 'testdist09',
    defaultProduction: 'manything1'
};

/**
 * Returns relevant self reg installer id for user based on their location and whether we're in "development".
 * @param {object} userLocation User location info returned from Ipdata.
 * @returns {string} Id of relevant installer.
 */
export const getSelfRegInstallerId = (userLocation) => {

    // Use installer with discounted pricing for certain regions
    if (isUserInDiscountedRegion(userLocation)) {
        return isDevelopment ? SELF_REG_INSTALLER_IDS.discountOregon : SELF_REG_INSTALLER_IDS.discountProduction;
    }

    return isDevelopment ? SELF_REG_INSTALLER_IDS.defaultOregon : SELF_REG_INSTALLER_IDS.defaultProduction;
}

/**
 * Returns relevant self reg distributor id for a new installer based on their location and whether we're in "development".
 * @param {object} userLocation User location info returned from Ipdata.
 * @returns {string} Id of relevant distributor.
 */
export const getSelfRegDistributorId = (userLocation) => {

    // Use distributor with discounted pricing for certain regions
    if (isUserInDiscountedRegion(userLocation)) {
        return isDevelopment ? SELF_REG_DISTRIBUTOR_IDS.discountOregon : SELF_REG_DISTRIBUTOR_IDS.discountProduction;
    }

    return isDevelopment ? SELF_REG_DISTRIBUTOR_IDS.defaultOregon : SELF_REG_DISTRIBUTOR_IDS.defaultProduction;
}

/**
 * Identify if the user's location qualifies them for our discounted price set.
 * @param {object} userLocation User location info returned from Ipdata.
 * @returns {boolean}
 */
export const isUserInDiscountedRegion = userLocation => {
    return (
        // Africa
        userLocation.continent_code === 'AF' ||
        // South America
        userLocation.continent_code === 'SA' ||
        // North America excluding USA, Canada and Greenland
        (
            userLocation.continent_code === 'NA' &&
            !['US', 'CA', 'GR'].includes(userLocation.country_code)
        ) ||
        // Asia exluding Turkey, Russia, Georgia, Azerbaijan, Armenia, Kazakhstan, United Arab Emirates,
        // Saudi Arabia, Israel, Qatar, Bahrain, Lebanon, Iraq, Jordan, Kuwait, Oman, Yemen, Palestine,
        // Iran and Syria (some of those may go down as Europe anyway)
        (
            userLocation.continent_code === 'AS' &&
            ![
                'TR', 'RU', 'GE', 'AZ', 'AM', 'KZ', 'AE', 'SA', 'IL', 'QA', 'BH', 'LB', 'IQ', 'JO', 'KW',
                'OM', 'YE', 'PS', 'IR', 'SY'
            ].includes(userLocation.country_code)
        )
    );
}